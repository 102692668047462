$white: #fff;
$black: #11142d;
$black-berry: #231c45;
$gray: #8f93a3;
$medium-gray: #c2c4cd;
$light-gray: #dfe1e5;
$super-light-gray: #f8f9fc;
$light-pink: #f9ecf2;
$dark-gray: #2c2e57;
$red: #d32f2f;
$sky-blue: #3f8cff;
$very-light-blue: #7280ff;
$blue: #2461a8;
$dark-blue: #2a488f;
$green: #27885f;
$yellow: #ffc000;
$cerise: #d4388a;
$purple: #4456dd;
$dark-purple: #3445c1;
$orange: #fd7a64;

$header-blue: #0a3751;
$header-gray: #f5f5f5;
$label-color: $gray;
$text-color: $dark-gray;
$text-light-color: #6b748d;
$text-medium-color: #535678;
$text-dark-color: #2c2e57;
$link-color: $purple;
$link-hover: #3445c1;
$placeholder-color: #c7c8cc;
$disable-color: #c7c8cc;
$alert-color: $cerise;
$error-color: $cerise;
$background-gray: #f8f9fc;
$background-blue: #f1f3ff;
$border-color: $light-gray;
$border-light-color: rgba(223, 225, 229, 0.1);
$separator-color: rgba(202, 204, 216, 0.18);
$shadow-color: rgba(68, 86, 221, 0.18);
$live-color: $very-light-blue;
$test-color: $orange;
$background-gray: #f8f9fc;
$background-app-draker: #e3e7f2;
$success-light: #ddf6e2;
$success: #27885f;
$lines: #dfe1e5;

$tag3: #1f8588;
$tag3-light: #def4f5;

// Brand Colors
$turquoise: #00d5e0;
$light-sea-green: #2db2b2;
$very-light-blue: #7280ff;
$wild-strawberry: #ff3f8f;
$amber: #ffc000;

// Brand Colors Variations
$turquoise-900: #003b45;
$turquoise-800: #005863;
$turquoise-700: #008591;
$turquoise-600: #00aeba;
// $turquoise-500: #00d5e0;
$turquoise-400: #34dce5;
$turquoise-300: #84e1e6;
$turquoise-200: #c6e9eb;
$turquoise-100: #e5f5f6;

$light-sea-green-900: #023832;
$light-sea-green-800: #0a5c56;
$light-sea-green-700: #19827f;
$light-sea-green-600: #1e9c99;
// $light-sea-green-500: #2db2b2;
$light-sea-green-400: #40c0c4;
$light-sea-green-300: #72d2d5;
$light-sea-green-200: #abe1e3;
$light-sea-green-100: #e4f5f6;

$very-light-blue-900: #192981;
$very-light-blue-800: #2d3dad;
$very-light-blue-700: #3d4ed1;
$very-light-blue-600: #5468fb;
// $very-light-blue-500: #7280ff;
$very-light-blue-400: #8d99ff;
$very-light-blue-300: #a4b5ff;
$very-light-blue-200: #bcd4ff;
$very-light-blue-100: #e7f0ff;

$wild-strawberry-900: #660f33;
$wild-strawberry-800: #94164b;
$wild-strawberry-700: #bd2866;
$wild-strawberry-600: #e83580;
// $wild-strawberry-500: #ff3f8f;
$wild-strawberry-400: #fa6bb2;
$wild-strawberry-300: #fc92c6;
$wild-strawberry-200: #f8dfb9;
$wild-strawberry-100: #faebf2;

$amber-900: #8c6500;
$amber-800: #ad7f00;
$amber-700: #cc9600;
$amber-600: #e8af00;
// $amber-500: #ffc000;
$amber-400: #fbd04b;
$amber-300: #f8dc86;
$amber-200: #fae6a9;
$amber-100: #f9f1d7;

// Grayscale
$blackberry: #231c45;
$text-dark: #2c2e57;
$text-medium: #535678;
$text-light: #6b748d;
$text-placeholder: #c7c8cc;
$lines: #dfe1e5;
$ghost: #f4f4f7;
$tag-background: #f4f4f7;
$background-gray: #f8f9fc;

// Primary
$primary: #4456dd;
$primary-hover: #3445c1;
$primary-selected: #e7f0ff;

// Notifications
$success: #27885f;
$success-light: #ddf6e2;
$error: #d4388a;
$error-light: #f9ecf2;
$warning: #93740a;
$warning-light: #fff09f;
$info: #5468fb;
$info-light: #e7f0ff;

// Dark Theme
$blackberry-light: #3a3262;
$blueberry: #494172;
$blueberry-lines: #605889;
$blackberry-text: #9590a5;

// Others
$happy-green: #86ca50;
$water-pink: #e99bc5;
$grapefruit: #fd7a64;
$background-blue: #f1f3ff;
$background-app: #f1f3f9;
$background-app-darker: #e3e7f2;
$highlight: #5765e5;
$highlight-disable: #4a4ea2;
$dark-wild-strawberry: #b92774;
$wild-strawberry-disable: #4f2354;
