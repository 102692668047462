@import '../../styles/base';

.main {
  padding-top: 152px !important;
  min-height: 100%;
  min-height: 100vh;
  background-color: $background-gray;
}

.navigation-bar {
  @extend %border-bottom;
  position: absolute;
  top: 0;
  right: 0;
  left: $sidebar-width;
  display: flex;
  padding: 76px $main-padding 20px;
  align-items: center;
  min-width: 800px;
  background-color: #fff;

  > button {
    width: 82px;
  }

  > h1 {
    font: 600 28px/36px $font-family;
    margin-left: 24px;
    margin-right: 8px;
  }

  > * {
    &:last-child {
      margin-left: auto;

      > div {
        > input {
          font: 300 13px/22px $font-family-mono;
        }
      }
    }
  }

  .order-id-tag {
    border-radius: 4px;
    padding: 0 16px;
    height: 32px;
    font-size: 12px;
    color: $text-medium-color;
    background-color: $background-gray;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid $background-app-draker;
  border-radius: 8px;
  padding: 24px;
  background-color: #fff;
}

.order-detail-container {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  gap: 24px;

  h2 {
    font: 600 16px/28px $font-family;

    i {
      margin-left: 14px;
      font-weight: normal;
      color: $text-light-color;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
  }

  .cols {
    display: flex;
    flex-direction: row;
    gap: 56px;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.events {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px 24px;
  font: 300 13px/16px $font-family;
  color: $text-medium-color;
  background-color: $background-gray;

  .lhs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .amount {
    font: 500 16px/1 $font-family;
    white-space: nowrap;
  }

  h3 {
    font: 600 13px/16px $font-family;
  }
}

.box.sponsored {
  color: $success;
  background-color: rgba(221, 246, 226, 0.3); // $sccuess-light, opacity 0.3

  .lhs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    b {
      font-weight: 500;
      color: $text-dark-color;
    }
    img {
      display: inline;
    }
  }

  .amount {
    color: $success;
  }
}

.event {
  .tag {
    border-radius: 4px;
    padding: 4px 16px;
    font: 600 12px/16px $font-family;
    color: #fff;
    background-color: $text-light-color;
  }

  time {
    font: 300 13px/16px $font-family;
  }

  .amount {
    font: 500 16px/1 $font-family;
  }

  &.succeeded,
  &.capture {
    background-color: rgba(221, 246, 226, 0.3); // $sccuess-light, opacity 0.3

    .tag {
      color: $success;
      background-color: $success-light;
    }
    .amount {
      color: $success;
    }
  }

  &.refund {
    .tag {
      color: $text-light-color;
      background-color: $lines;
    }
  }
}

.amount-box {
  padding: 24px;

  &.alt {
    padding: 16px;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font: 500 18px/18px $font-family;

    .label {
      color: $text-dark-color;
    }
    .value {
      color: $purple;

      &.requires_capture {
        color: $text-light-color;
      }
    }
  }

  hr {
    margin: 16px 0;
    border: none;
    height: 1px;
    background-color: $separator-color;
  }

  .note {
    font: 300 13px/16px $font-family;
    color: $text-medium-color;
  }

  .onhold {
    margin-left: 8px;
  }

  &.merchant-pay {
    .line {
      .value {
        color: $success;

        &.requires_capture {
          color: $text-light-color;
        }
      }
    }
  }
}

.order-detail {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-top;
  justify-content: space-between;

  > div {
    flex: 1;
  }
}

.meta-container {
  width: 100%;

  > div {
    font: 400 13px/13px $font-family;
    background-color: $super-light-gray;
    color: $text-light-color;
    border-radius: 6px;
    display: flex;
    margin-bottom: 8px;
    padding: 8px 24px;

    > * {
      width: 50%;
    }

    :first-child {
      font: 600 13px/13px $font-family;
      color: $dark-gray;
    }
  }
}

.action-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;

  > button {
    padding: 0 24px;
    width: auto;
  }
}
