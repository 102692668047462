@import '../../styles/base';

.aside {
  background: $black-berry;
  width: $corporate-sidebar-width;
  flex: 0 0 $corporate-sidebar-width;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  z-index: 1;

  > * {
    &:first-child {
      padding: 18px;
    }
  }

  nav {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .top {
      > a {
        @extend %sidebar-item;
      }

      a,
      button {
        padding: 0 24px;
      }
    }

    .toggler {
      padding: 16px 12px;

      .block {
        border-top: 1px solid $border-light-color;
      }

      .block > a {
        @extend %sidebar-item;
        padding: 16px 24px;
        height: auto;
      }
    }
  }
}

.logo {
  margin: 0 auto 40px;
}
