@import '../styles/base';

:root {
  // fonts
  --fallback-fonts: '-apple-system', BlinkMacSystemFont, 'Hiragino Sans', Osaka,
    sans-serif;
  --font-family: 'Noto Sans JP', var(--fallback-fonts);
  --font-family-number: 'Filson Pro Numbers', 'Noto Sans JP',
    var(--fallback-fonts);
  --font-family-mono: 'SF Mono', monospace;
  --base-font-size: 16px;
  // wrapper vars
  --max-wrapper-width: 1130px;
}

.root {
  flex: 1;
  background: $white;
  min-width: $min-device-width;
  display: flex;

  > .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - #{$ribbon-height});
    position: absolute;
    top: $ribbon-height;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
  }

  main {
    padding: 96px $main-padding 48px $sidebar-width + $main-padding;
    min-width: 960px;
    width: 100%;

    > h1 {
      font: 600 28px/36px $font-family;
      margin-bottom: 24px;
    }
  }

  &.corporate {
    main {
      padding: 96px $main-padding 48px $corporate-sidebar-width + $main-padding;
    }

    .header {
      left: $corporate-sidebar-width;
      width: calc(100% - #{$corporate-sidebar-width});
    }

    .strip-container {
      left: $corporate-sidebar-width;
      width: calc(100% - #{$corporate-sidebar-width});
    }
  }
}

.logo {
  margin: 0 auto 40px;
}

.header {
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
}

.strip-container {
  position: fixed;
  top: 48px;
  width: calc(100% - #{$sidebar-width});
  left: $sidebar-width;
  border-top: 3px solid $test-color;
  z-index: 10000;
  pointer-events: none;

  .strip {
    display: flex;
    justify-content: center;

    &::before {
      content: url('../assets/strip-before.svg');
      width: 20px;
      height: 26px;
    }

    &::after {
      content: url('../assets/strip-after.svg');
      width: 20px;
      height: 26px;
    }

    > span {
      font: 700 12px/20px $font-family;
      display: flex;
      align-items: center;
      background-color: $test-color;
      color: $white;
      padding: 0px 16px;
      user-select: none;

      > img {
        margin-right: 4px;
        user-select: none;
      }
    }
  }
}
